.root {
  mask-repeat: no-repeat;
  mask-size: 100% 100%;
  mask-image: url("/mask.webp");
}

@media (min-width: 640px) {
  .root {
    mask-image: url("/mask-xs.webp");
  }
}

@media (min-width: 768px) {
  .root {
    mask-image: url("/mask-sm.webp");
  }
}

@media (min-width: 1024px) {
  .root {
    mask-image: url("/mask-desktop.webp");
  }
}
