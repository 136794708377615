@keyframes left {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(-200%);
  }

  75% {
    opacity: 0;
  }

  100% {
    transform: translateX(0);
  }
}

.root {
  &:hover .wheel,
  &:focus .wheel {
    animation-play-state: paused;
  }
}

.wheel {
  animation: 1s ease-in-out 0s infinite left;
}
