@keyframes translateScroller {
  from {
    transform: translateX(20%);
  }
  to {
    transform: translateX(-20%);
  }
}

.root {
  mask: linear-gradient(90deg, transparent, white 20% 80%, transparent);
}

@supports (animation-timeline: view()) {
  .root {
    animation: translateScroller linear;
    animation-timeline: view();
    // framer-motion eq : start end, end start
    animation-range: entry 0% exit 100%;
  }
}

@media (prefers-reduced-motion) {
  .root {
    animation: none;
    mask: none;
  }
}
