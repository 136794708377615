@keyframes parallaxLeave {
  to {
    transform: translateY(100px);
    opacity: 0;
  }
}

@supports (animation-timeline: view()) {
  .root {
    animation: parallaxLeave linear forwards;
    animation-timeline: view();
    /* framer-motion eq : end end, start start */
    animation-range: entry 100% exit 0%;
  }
}
.root {
  margin-bottom: 100px;
}

@media (prefers-reduced-motion) {
  .root {
    animation: none;
  }
}
