.root {
  --content-gap: 10vw;
  @keyframes banner {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(calc(-50% - var(--content-gap) - 0.5ch));
    }
  }
  column-gap: var(--content-gap);
  animation: banner 10s linear infinite both;
  &.direction-backward {
    animation-direction: reverse;
  }
}

@media (prefers-reduced-motion) {
  .root {
    animation: none;
    column-gap: 3vw;
  }
}
