@keyframes down {
  0% {
    transform: translateY(0);
  }

  10% {
    transform: translateY(200%);
  }

  15% {
    opacity: 0;
  }

  30% {
    transform: translateY(0);
  }

  40% {
    opacity: 1;
  }
}

.root {
  &:hover .wheel,
  &:focus-within .wheel {
    animation-play-state: paused;
  }
}

.wheel {
  animation: 3s ease-in-out 0s infinite down;
}
