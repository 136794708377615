@keyframes parallaxEnter {
  from {
    scale: 0.3;
  }
  to {
    scale: 1;
  }
}

@supports (animation-timeline: view()) {
  .root {
    animation: parallaxEnter linear forwards;
    animation-timeline: view();
    /* framer-motion eq : start end, end end */
    animation-range: entry 0% entry-crossing 100%;
  }
}

@media (prefers-reduced-motion) {
  .root {
    animation: none;
  }
}
