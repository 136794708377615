.root {
  @keyframes reveal {
    0% {
      opacity: 0;
      transform: translateY(var(--parallax-factor));
    }
    20% {
      opacity: 1;
      transform: translateY(0);
    }
    90% {
      opacity: 1;
      transform: translateY(0);
    }

    100% {
      opacity: 0;
      transform: translateY(0);
    }
  }
  @supports (animation-timeline: view()) {
    & * {
      animation: reveal linear both;
      animation-timeline: view();
      // framer-motion eq : start end, end start
      animation-range: entry 0% exit 100%;
    }
  }
}
